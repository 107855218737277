<template>
  <div>
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            이모션글로벌과<br />
            함께라면 그 어떤 것도<br />
            할 수 있고, 어떤 것이라도<br />
            될 수 있습니다.
          </div>
        </div>
        <div class="bg"></div>
      </div>
    </div>
    <div class="tab-contents">
      <tab-item v-if="!selectShow" :list="tab.list" :name="tab.name" v-model="tab.active" />
      <form-select
        v-else
        type="tab"
        v-model="tab.active"
        :options="
          tab.list.map(el => {
            return {
              value: String(el.id),
              label: el.title
            };
          })
        "
      />
      <div class="careers-wrap">
        <div class="contents">
          <!-- //Culture -->
          <div v-show="tab.active === '0'" class="culture">
            <div class="title">
              하루의 가장 많은 시간을 보내는 공간,<br />
              <em>더 좋은 업무 환경을 만들어 갑니다.</em>
            </div>
            <div v-swiper:mySwiper2="swiperPanorama" class="panorama-swiper">
              <div class="swiper-wrapper swiper-area">
                <div
                  class="swiper-slide swiper-item"
                  v-for="(item, index) in careerController.companyView"
                  :key="index"
                >
                  <span class="thumb">
                    <img :src="imgUrl(item.pcImagePhysicalName)" alt="item.pcImageName" />
                  </span>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <ul class="benefits-list">
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit01.png" alt="" />
                </span>
                <strong>법적 복리후생</strong>
                <p>
                  기본을 단단하게 지켜드려요!<br />
                  4대 보험 적용 및 퇴직연금 제도
                </p>
              </li>
              <li>
                <span class="thumb"
                  ><img src="../../assets/images/career/ico-benefit02.png" alt="" />
                </span>
                <strong>건강검진 지원</strong>
                <p>
                  2년에 한 번씩 건강체크!<br />
                  건강검진비용 지원
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit03.png" alt="" />
                </span>

                <strong>휴가제도</strong>
                <p>
                  열심히 일한 이글리안, 떠나라!<br />
                  연차 휴가제도 운영
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit04.png" alt="" />
                </span>
                <strong>근무제도</strong>
                <p>
                  조금 더 여유있는 근무를 위한<br />
                  주 5일 근무 및 시차 출퇴근제
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit05.png" alt="" />
                </span>
                <strong>생일자 혜택</strong>
                <p>
                  행복한 날을 더 행복하게,<br />
                  상품권 지급 & 조기퇴근 제도
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit06.png" alt="" />
                </span>
                <strong>사내시설</strong>
                <p>
                  여유있고 쾌적한 근무를 위해!<br />
                  카페테리아, 회의실 및 휴게실 운영
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit07.png" alt="" />
                </span>

                <strong>해피런치</strong>
                <p>
                  맛있는 식사, 조금 더 천천히 즐겨요~<br />
                  매일 점심시간 1시간 30분 운영
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit08.png" alt="" />
                </span>

                <strong>복지포인트 지원</strong>
                <p>자기개발과 문화생활을 위한 복지포인트 지급</p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit09.png" alt="" />
                </span>
                <strong>출산 선물</strong>
                <p>본인 또는 배우자 출산 시, 출산 축하금 & 선물 지급</p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit10.png" alt="" />
                </span>

                <strong>장기근속 포상</strong>
                <p>
                  3년 / 6년 / 10년 단위로<br />
                  장기근속 포상금 지급
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit11.png" alt="" />
                </span>

                <strong>인사 추천 제도</strong>
                <p>
                  좋은 사람있으면 소개시켜 주세요!<br />
                  인재 추천 시 추천인에게 포상금 지급
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit12.png" alt="" />
                </span>

                <strong>경조사 지원</strong>
                <p>
                  기쁜 일과 슬픈 일은 모두 함께,<br />
                  경조휴가 및 경조금 지급
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit13.png" alt="" />
                </span>
                <strong>택시비 지원</strong>
                <p>
                  조금 더 안전한 귀가와 근무를 위해<br />
                  야근 및 외근 시 택시비 지원
                </p>
              </li>
              <li>
                <span class="thumb">
                  <img src="../../assets/images/career/ico-benefit14.png" alt="" />
                </span>
                <strong>명절 선물</strong>
                <p>
                  행복한 명절을 더욱 풍성하게!<br />
                  설날 / 추석 명절 선물 지급
                </p>
              </li>
            </ul>
          </div>
          <!-- Culture// -->

          <!-- //Apply -->
          <div v-show="tab.active === '1'" class="apply">
            <div class="job-type">
              <div class="title">
                <em>이모션글로벌</em>과 함께 치열하게 고민하고<br />
                나아갈 <em>당신을 기다립니다.</em>
              </div>
              <div class="title black">지원하고 싶은 분야를 선택해 주세요.</div>
              <accordion>
                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Consultant</div>
                  </template>
                  <template slot="accordion-content">
                    <div class="acc-expend">
                      <div class="title">
                        우리는 사용자 관점과<br />
                        경험을 중심으로<br />
                        트렌드를 선도해 나아갈<br />
                        컨설턴트를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img :src="require('@/assets/images/career/consultant.webp')" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>UX 프로젝트 참여 및 수행 경험(학과 프로젝트 포함)</li>
                              <li>정량적, 정성적 Research에 대한 이해를 바탕으로 한 수행 경험</li>
                              <li>UX 디자인 프로세스 프로젝트 경험</li>
                              <li>IA설계, 프로토타이핑 설계 경험</li>
                              <li>
                                UX 전략/컨셉 도출 및 개념 정의 등 해결 솔루션 제안 능력 있으신 분
                              </li>
                              <li>
                                User Research, Persona 정의, Customer Journey Map 등의 모델링 수행
                                능력 있으신 분
                              </li>
                              <li>마케팅 분야, 기술/디자인에 대한 깊은 이해</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>UX, HCI, 서비스 디자인 관련 전공자</li>
                                <li>사용성 테스트 진행 경험</li>
                                <li>UI 프로젝트 실무 컨트롤 및 커뮤니케이션 능력</li>
                                <li>데이터 기반 서비스 개선 및 문제해결 능력</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>유관 경력 5년 이상</li>
                              <li>컨설팅 및 UX설계 등 다양한 디지털 프로젝트 수행 경험</li>
                              <li>사용성 평가 기획 및 진행(UT, In-depth Interview, Survey) 경험</li>
                              <li>
                                디지털 프로젝트 컨설팅, 기획, 개발, 운영까지 전체 프로세스 총괄 경험
                              </li>
                              <li>
                                Customer & Business 이해 기반 사용자 리서치 주제, 정의, 설계, 분석
                                가능하신 분
                              </li>
                              <li>사용자 행동, 동기, 태도 분석을 통한 모델링 가능하신 분</li>
                              <li>UT 진행, 리서치 ,데이터 분석을 통한 UX개선점 도출 가능하신 분</li>
                              <li>대형 프로젝트를 주도적으로 매니징, 리딩할 수 있으신 분</li>
                              <li>
                                신규 서비스 발굴, 기획 가능하신 분(프로덕트 비전 및 전략, 로드맵
                                수립)
                              </li>
                              <li>여러 이니셔티브의 우선순위 선정 및 일정관리 가능하신 분</li>
                              <li>제안서 작성, 기술협의, 기술자료 및 리포트 작성 가능하신 분</li>
                              <li>전략적/논리적 사고 및 유연한 커뮤니케이션 가능하신 분</li>
                              <li>협업할 수 있는 커뮤니케이션 능력 및 관련 지식</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>
                                  UX, HCI, 서비스디자인, HCI, 인지과학 관련 전공자 혹은 석사학위
                                  이상
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button type="button" @click="goSubmit()"><span>지원하기</span></button>
                        </div>
                      </div>
                    </div>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Planner</div>
                  </template>
                  <template slot="accordion-content">
                    <div class="acc-expend">
                      <div class="title">
                        데이터를 기반으로 분석하고,<br />
                        크레이티브를 언어로 다룰줄 알며,<br />
                        기술에 기반한 설득의 논리를 가진<br />
                        기획자를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img :src="require('@/assets/images/career/planner.webp')" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>온라인 서비스 모바일/웹/APP 분야에 관심이 있으신 분</li>
                              <li>신규 온라인 서비스에 대한 관심과 열정이 있으신 분</li>
                              <li>원활한 커뮤니케이션으로 배려와 설득에 자신 있으신 분</li>
                              <li>다양한 사고를 통해 아이디어를 구조화할 수 있으신 분</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>웹에이전시/SI 경험(또는 관련 전공자)</li>
                                <li>
                                  제안서 작성(트렌드 및 현황분석, 전략/방안 수립) 및 PT 가능하신 분
                                </li>
                                <li>마케팅 관련 기획 가능하신 분</li>
                                <li>다국어(영어, 일어, 중국어 등) 커뮤니케이션 가능하신 분</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>유관 경력 5년 이상</li>
                              <li>PC/Mobile UIUX 설계/기획 경력</li>
                              <li>Admin, Back-end 설계/기획 경력</li>
                              <li>서비스 및 관련 정책 기획 가능하신 분</li>
                              <li>다양한 분석을 통한 인사이트 도출 가능하신 분</li>
                              <li>고객사 및 유관 파트와 원활한 커뮤니케이션 능력</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>PM, 기획PL 경력</li>
                                <li>웹에이전시/SI 경력 우대</li>
                                <li>
                                  제안서 작성(트렌드 및 현황분석, 전략/방안 수립) 및 PT 가능하신 분
                                </li>
                                <li>마케팅 관련 기획 가능하신 분</li>
                                <li>다국어(영어, 일어, 중국어 등) 커뮤니케이션 가능하신 분</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button type="button" @click="goSubmit()"><span>지원하기</span></button>
                        </div>
                      </div>
                    </div>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Designer</div>
                  </template>
                  <template slot="accordion-content">
                    <div class="acc-expend">
                      <div class="title">
                        우리는 열정이 가득하고<br />
                        크리에이티브한 <br />
                        디자이너를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img :src="require('@/assets/images/career/designer.webp')" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>온라인 서비스 IT/Design 분야에 대한 관심과 열정이 있는 분</li>
                              <li>디자인 기획력/컨셉 도출 능력이 뛰어나신 분</li>
                              <li>원활한 커뮤니케이션 보유자</li>
                            </ul>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>디자인 업무 리딩 경험자</li>
                              <li>
                                온라인 서비스 IT/Design 분야에 대한 깊은 이해와 감각이 있는 분
                              </li>
                              <li>디자인 기획력/컨셉 도출 능력이 뛰어나신 분</li>
                              <li>클라이언트 및 부서간 원활한 커뮤니케이션 능력</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>디자인 업무 리딩 경험</li>
                                <li>
                                  온라인 서비스 IT/Design 분야에 대한 깊은 이해와 감각이 있는 분
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button type="button" @click="goSubmit()"><span>지원하기</span></button>
                        </div>
                      </div>
                    </div>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Publisher</div>
                  </template>
                  <template slot="accordion-content">
                    <div class="acc-expend">
                      <div class="title">
                        우리는 높은 이해력과<br />
                        유연한 사고를 가진<br />
                        퍼블리셔를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img :src="require('@/assets/images/career/publisher.webp')" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>html, css, javascript를 이해하고 업무에 활용 가능자</li>
                              <li>웹 표준 및 웹 접근성 관련 개념을 갖추고 계신 분</li>
                              <li>형상관리(Git)의 작동원리를 이해하고 사용 가능한 분</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>
                                  vuejs와 react 등 SPA 프레임워크에 대한 개념을 갖추고 계신 분
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>
                                frontend 테스트 및 배포 자동화 환경에 대한 이해를 갖추고 계신 분
                              </li>
                              <li>frontend 개발 관련 효과적인 커뮤니케이션 가능자</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>vuejs와 react 등 SPA 프레임워크를 활용한 개발 가능자</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button type="button" @click="goSubmit()"><span>지원하기</span></button>
                        </div>
                      </div>
                    </div>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Developer</div>
                  </template>
                  <template slot="accordion-content">
                    <div class="acc-expend">
                      <div class="title">
                        우리는 디테일한 부분까지<br />
                        완전함을 추구하는<br />
                        디벨로퍼를 기다립니다
                      </div>
                      <div class="thumb-img">
                        <img :src="require('@/assets/images/career/developer.webp')" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>Java, .net 개발 가능자 및 전산 관련 전공자</li>
                              <li>프로그램 개발 학원 수료자(Java,.net)</li>
                              <li>Linux, Windows 기반 개념이 있으신 분</li>
                              <li>
                                최신 기술 및 트랜드에 대한 관심과 익히고자 하는 열망이 있으신 분
                              </li>
                            </ul>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>Java Spring Framework, Maven 개발 가능자</li>
                              <li>Oracle, MSSQL, MYSQL, MariaDB 설계 가능자 및 경험자</li>
                              <li>Python, NodeJS, Angular, React 경험이 있거나 관심이 있으신 분</li>
                              <li>Github, Zenkins 경험자</li>
                              <li>최신 트랜드에 대한 관심과 개발에 대한 거부감이 없으신 분</li>
                            </ul>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button type="button" @click="goSubmit()"><span>지원하기</span></button>
                        </div>
                      </div>
                    </div>
                  </template>
                </accordion-item>
              </accordion>
            </div>
            <form class="careers-form" @submit.prevent="uploadFile" ref="form">
              <h3 class="title black">지원 정보를 입력해주세요.</h3>
              <fieldset>
                <legend>지원자 정보</legend>
                <ul class="form-input-list">
                  <li>
                    <input
                      type="text"
                      placeholder="이름"
                      v-model="formData.applicantName"
                      :maxlength="maxlength"
                    />
                  </li>
                  <li>
                    <input type="tel" placeholder="전화번호" v-model="formData.applicantNumber" />
                  </li>
                  <li>
                    <input
                      type="email"
                      placeholder="이메일"
                      v-model="formData.applicantEmail"
                      :maxlength="maxlength"
                      ref="emailInput"
                    />
                    <span v-show="isError">이메일 형식이 유효하지 않습니다.</span>
                  </li>

                  <li>
                    <input
                      type="text"
                      placeholder="(선택) 참조 사이트 주소"
                      v-model="formData.applicantUrl"
                    />
                  </li>
                </ul>
              </fieldset>
              <fieldset>
                <legend>지원분야</legend>
                <ul class="form-list">
                  <li v-for="item in supportingField.list" :key="item.value">
                    <form-radio
                      :disabled="item.disabled"
                      :val="item.value"
                      name="supportingField"
                      v-model="supportingField.value"
                    >
                      {{ item.label }}
                    </form-radio>
                  </li>
                </ul>
                <form-select
                  placeholder="지원분야를 선택해주세요."
                  required
                  v-model="supportingSelect.value"
                  :options="supportingSelect.options"
                />
              </fieldset>

              <fieldset>
                <legend>지원 내용</legend>
                <div class="memo-box">
                  <textarea
                    v-model="formData.applicantContents"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="지원 동기 등 자유롭게 작성해 주세요."
                  ></textarea>
                  <ul class="file-list" v-if="uploadFiles">
                    <li v-for="(item, index) in uploadFiles" :key="index">
                      <span>{{ item.name }}({{ fileSize(item.size) }}MB)</span>
                      <button type="button" class="file-del" @click="fileDelete(index)">
                        삭제
                      </button>
                    </li>
                  </ul>
                  <div class="file-box">
                    <input
                      type="file"
                      multiple
                      @change="fileChange"
                      accept=".pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpg, .png, .zip"
                    />
                    <span class="find">파일첨부</span>
                    <div class="desc">
                      <p>
                        이력서, 포트폴리오 등 5개까지 업로드 가능 <br />(최대 10MB) pdf, ppt, word,
                        excel, jpg, png
                      </p>
                    </div>
                  </div>
                </div>

                <privacy :dialogVisible.sync="dialogVisible" />
                <div class="agree-box">
                  <form-checkbox v-model="formData.privacy">
                    <a href="#" @click.prevent="dialogVisible = true" class="under-red"
                      >개인정보보호정책</a
                    >에 동의합니다.
                  </form-checkbox>
                </div>
              </fieldset>
              <div class="careers-info">
                <div class="info-inner">
                  저는
                  <span :class="{active: !this.formData.applicantName.length}">{{ name }}</span
                  >입니다. <br />
                  제 번호는
                  <span :class="{active: !this.formData.applicantNumber.length}">{{ number }}</span
                  >고, <br />
                  제 메일은
                  <span :class="{active: !this.formData.applicantEmail.length}">{{ email }}</span
                  >입니다. <br />
                  <br />
                  <span :class="{active: !this.supportingField.value}">{{ supportFieldValue }}</span
                  >으로<br />
                  <span :class="{active: !this.supportingSelect.value}">{{
                    supportSelectValue
                  }}</span
                  >에 지원합니다.
                </div>
              </div>
              <div class="careers-btn">
                <button type="submit" :disabled="validate" @click="checkEmail($event)">
                  <span>지원하기</span>
                </button>
              </div>
            </form>
          </div>
          <!-- Apply// -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {TweenMax, Power0} from "gsap/all";
  import Accordion from "@/components/global/accordion";
  import AccordionItem from "@/components/global/accodion-item";
  import {apiCode, apiFile, apiCareer, apiCareerController} from "@/api";
  import bus from "@/utils/bus";
  import {commonMethods} from "@/utils/common-methods";
  import privacy from "@/views/privacy/index";

  export default {
    name: "Careers",
    metaInfo() {
      return {
        title: "Careers",
        titleTemplate: "%s | e · motion"
      };
    },
    components: {
      // formSelect,
      Accordion,
      AccordionItem,
      privacy
    },
    mixins: [commonMethods],
    data() {
      return {
        selectShow: false,
        isError: false,
        maxlength: 40,
        scene: null,
        tab: {
          name: "career-tab",
          list: [
            {
              id: "0",
              title: "Culture"
            },
            {
              id: "1",
              title: "Apply"
            }
          ],
          active: "0"
        },
        dialogVisible: false,
        agree: false,
        maxFileLength: 5,
        supportingField: {
          list: [],
          value: ""
        },
        supportingSelect: {
          options: [],
          value: ""
        },
        uploadFiles: [],
        formData: {
          experience: "",
          applicantPosition: "",
          applicantName: "",
          applicantEmail: "",
          applicantNumber: "",
          applicantContents: "",
          applicantUrl: "",
          privacy: false,
          fileList: []
        },
        careerController: {},
        swiperPanorama: {
          observer: true,
          observerParents: true,
          loop: true,
          autoplay: {
            delay: 3000
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
          }
        }
      };
    },
    computed: {
      validate() {
        return (
          !this.formData.experience ||
          !this.formData.applicantPosition ||
          !this.formData.applicantName ||
          !this.formData.applicantEmail ||
          !this.formData.applicantNumber ||
          !this.formData.applicantContents ||
          !this.formData.privacy
        );
      },
      isValid() {
        const regExp = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return regExp.test(this.formData.applicantEmail);
      },
      name() {
        return this.txt(this.formData.applicantName, "이름");
      },
      number() {
        const num = this.formData.applicantNumber
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

        return this.txt(num, "000-0000-0000");
      },
      email() {
        return this.txt(this.formData.applicantEmail, "이메일");
      },
      supportFieldValue() {
        const value = this.txt(this.supportingField.value, "신입 / 경력");
        if (value === "WET_NEW") {
          return "신입";
        } else if (value === "WET_EXPERIENCE") {
          return "경력";
        } else {
          return value;
        }
      },
      supportSelectValue() {
        const value = this.txt(this.supportingSelect.value, "지원 분야");
        if (this.supportingSelect.value) {
          const str = value.replace("PST_", "").toLowerCase();
          const newStr = str.replace(/^[a-z]/, char => char.toUpperCase());
          return newStr;
        } else {
          return value;
        }
      }
    },
    watch: {
      "tab.active"() {
        this.init();
      },
      isValid() {
        if (this.isValid) {
          this.isError = false;
        }
      },
      "formData.applicantNumber"(n, o) {
        if (n.length > 11) {
          this.formData.applicantNumber = o;
        }
      },
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      },
      "supportingSelect.value"(val) {
        this.formData.applicantPosition = val;
      },
      "supportingField.value"(val) {
        this.formData.experience = val;
      }
    },
    activated() {
      this.init();
    },
    methods: {
      windowScrollEvt() {
        const tabContents = document.querySelector(".tab-contents");
        if (tabContents) {
          if (tabContents.offsetTop <= window.scrollY + 1) {
            this.selectShow = true;
          } else {
            this.selectShow = false;
          }
        }
      },
      // 이메일 유효성 체트
      checkEmail(e) {
        if (!this.isValid) {
          e.preventDefault();
          this.isError = true;
          this.goSubmit();
        } else {
          this.isError = false;
        }
      },
      // 텍스트 상태
      txt(a, b) {
        return a ? a : b;
      },
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2]);
        this.$scrollmagic.addScene(this.scene);
      },
      goSubmit() {
        window.scrollTo({
          top: document.querySelector(".careers-form").offsetTop,
          behavior: "smooth"
        });
      },
      fileSize(size) {
        let value = size / 1024 / 1024;
        let exp = -1;
        if (typeof exp === "undefined" || +exp === 0) {
          return Math.ceil(value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
          return NaN;
        }
        value = value.toString().split("e");
        value = Math.ceil(+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
        value = value.toString().split("e");
        return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
      },
      fileDelete(index) {
        this.uploadFiles.splice(index, 1);
      },
      fileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (this.uploadFiles.length + files.length > this.maxFileLength) {
          alert(` 이력서, 포트폴리오 등 ${this.maxFileLength}개까지 업로드 가능 합니다.`);
          return;
        }
        files.forEach(el => {
          if (el.size <= 1024 * 1024 * 10) {
            this.uploadFiles.push(el);
          } else {
            alert("최대 10MB까지 업로드 가능 합니다.");
          }
        });
      },

      async uploadFile() {
        bus.$emit("loading", true);
        Promise.all(
          this.uploadFiles.map(async el => {
            try {
              const formData = new FormData();
              formData.append("files", el);
              const {
                data: {data: response}
              } = await apiFile("career", formData);
              this.formData.fileList.push({
                careerFileName: response[0].originName,
                careerPhysicalFileName: response[0].physicalName
              });
            } catch (e) {
              console.log(e);
            }
          })
        )
          .then(() => {
            this.submit();
          })
          .catch(e => {
            bus.$emit("loading", false);
            console.log(e);
          });
      },

      async code(code, targetData) {
        try {
          const {
            data: {data: response}
          } = await apiCode(code);
          response.forEach(el => {
            targetData.push({
              value: el.commonCode,
              label: el.commonCodeDescription
            });
          });
        } catch (e) {
          console.log(e);
          return [];
        }
      },
      async submit() {
        try {
          const {data: response} = await apiCareer(this.formData);
          if (response.code === "SUCCESS") {
            await this.$refs.form.reset();
            alert("지원이 완료 되었습니다.");
            this.formData = {
              experience: "",
              applicantPosition: "",
              applicantName: "",
              applicantEmail: "",
              applicantNumber: "",
              applicantContents: "",
              applicantUrl: "",
              privacy: false,
              fileList: []
            };
            this.supportingField.value = null;
            this.supportingSelect.value = null;
            this.uploadFiles = [];
          } else {
            alert(response.message);
          }
          await bus.$emit("loading", false);
        } catch (error) {
          await bus.$emit("loading", false);
          console.log(error);
        }
      },
      async careerSwiper() {
        try {
          const {
            data: {data: response}
          } = await apiCareerController({
            response: true,
            alert: true,
            code: "",
            message: ""
          });
          this.careerController = response;
        } catch (error) {
          console.log(error);
        }
      }
    },
    created() {
      this.careerSwiper();
    },
    mounted() {
      this.init();
      this.code("WORK_EXPERIENCE_TYPE", this.supportingField.list);
      this.code("POSITION_TYPE", this.supportingSelect.options);
      document.addEventListener("scroll", this.windowScrollEvt);
    },
    destroyed() {
      document.removeEventListener("scroll", this.windowScrollEvt);
    }
  };
</script>
<style lang="scss" scoped>
  .key-visual {
    position: relative;
    min-height: 779px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      .txt {
        @include montserrat;
        margin-top: -30px;
        height: 369px;
        font-weight: 700;
        font-size: 30px;
        line-height: 43px;
        letter-spacing: -0.03em;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background: url(../../assets/images/career/img_careers_main.webp) center center / cover
        no-repeat;
    }
  }
  .careers-wrap {
    padding: 50px 0 80px;
    .title {
      font-size: 18px;
      font-weight: 700;
      color: #a0a0a0;
      line-height: 28px;
      letter-spacing: -0.05em;
      font-family: "Noto Sans KR", sans-serif;
      &.black {
        color: #000;
        margin-top: 50px;
      }

      em {
        color: $red;
      }
    }
  }
  .panorama-swiper {
    overflow: hidden;
    width: calc(100% + 40px);
    padding-bottom: 23px;
    margin: 37.5px -20px 0;
    ::v-deep.swiper-pagination-bullets {
      bottom: 0;
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0 5px;
        background: #ddd;
        opacity: 1;
        transition: width ease-in-out 0.2s;
        &.swiper-pagination-bullet-active {
          width: 21px;
          background: #ee2c3c;
          border-radius: 6px;
          opacity: 1;
        }
      }
    }
  }
  .benefits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 1% 0 9%;
    margin: 45px -20px 0;
    li {
      flex: 0 0 auto;
      width: calc(100% / 3);
      min-width: 85px;
      box-sizing: border-box;
      padding-right: 20px;
      word-break: keep-all;
      &:nth-child(n + 4) {
        margin-top: 41px;
      }
      .thumb {
        display: block;
        width: 50px;
      }
      strong {
        display: block;
        margin-top: 15px;
        font-size: 13px;
        font-weight: 700;
        color: #333;
        letter-spacing: -0.03em;
      }
      p {
        margin-top: 10px;
        font-size: 10px;
        line-height: 14px;
        color: #888;
        letter-spacing: -0.05em;
      }
    }
  }
  .careers-process {
    min-width: 320px;
    margin: 58px -20px 0;
    padding: 30px 20px 54px;
    background: #eb1624;
    box-sizing: border-box;
    .title {
      color: #fff;
    }
    .sub-txt {
      margin-top: 35px;
      font-size: 11px;
      line-height: 21px;
      color: #fff;
      span {
        margin-left: 12px;
        @include montserrat();
      }
    }
  }
  .careers-process-step {
    display: flex;
    flex-wrap: wrap;
    margin-top: 39px;
    justify-content: space-between;

    li {
      position: relative;
      width: 36vw;
      height: 36vw;
      border: 1px solid #fff;
      border-radius: 50%;

      &:nth-child(n + 3) {
        margin-top: 62px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 46px;
        height: 8px;
        background: url(../../assets/images/career/step-arrow.png) no-repeat 0 0/100%;
      }
      &:first-child:after {
        top: 50%;
        right: -46px;
        transform: translateY(-50%);
      }
      &:nth-child(2):after {
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
      &:nth-child(3) {
        order: 1;
      }
      &:nth-child(3):after {
        top: 50%;
        left: -47px;
        transform: translateY(-50%) rotate(-180deg);
      }
      &:last-child:after {
        display: none;
      }
      span {
        display: block;
        margin-top: 21.5%;
        text-align: center;
        @include montserrat();
        font-size: 10px;
        color: #fb838b;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        text-align: center;
      }
    }
  }
  .job-type {
    .accordion {
      margin-top: 20px;
    }
    .acc-tit {
      position: relative;
      @include montserrat();
      padding: 29px 0;
      font-size: 16px;
      font-weight: 700;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        margin-right: 15px;
        transform: translateY(-50%);
        width: 16px;
        height: 8.5px;
        background: url(../../assets/images/acc-off.png) no-repeat 0 0/100%;
      }
    }
    .acc-expend {
      position: relative;
      padding-bottom: 60px;
      .title {
        padding-top: 40px;
        font-size: 18px;
        letter-spacing: -0.03em;
        line-height: 28px;
        color: #000;
      }
      .thumb-img {
        z-index: -1;
        position: absolute;
        top: 27px;
        left: 40px;
        max-width: 280px;
      }
      .content {
        margin-top: 100px;
        dl {
          display: flex;
          justify-content: space-between;
          margin-left: 6px;
          & + dl {
            margin-top: 37px;
          }
          dt {
            width: 60px;
            font-size: 15px;
            letter-spacing: -0.05em;
            font-weight: 700;
          }
          dd {
            width: calc(100% - 60px);
            ul {
              li {
                position: relative;
                padding-left: 12px;
                font-size: 13px;
                line-height: 17px;
                letter-spacing: -0.05em;
                color: #333;
                & + li {
                  margin-top: 10px;
                }
                &:before {
                  content: "";
                  position: absolute;
                  top: 8.5px;
                  left: 0;
                  width: 2px;
                  height: 2px;
                  background: #333;
                  border-radius: 50%;
                }
              }
            }
            .preference {
              position: relative;
              display: flex;
              margin: 56px 0 0 12.5px;
              &:before {
                content: "";
                position: absolute;
                top: -26px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #ee2c3c;
              }
              .txt {
                width: 60px;
                font-size: 11px;
                font-weight: bold;
              }
              ul {
                width: calc(100% - 60px);
                li {
                  padding: 0;
                  font-size: 11px;
                  line-height: 14px;
                  color: #888;

                  &:before {
                    display: none;
                  }
                  & + li {
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .accordionTrigger.accordionTriggerActive .acc-tit:after {
      background: url(../../assets/images/acc-on.png) no-repeat 0 0/100%;
    }
  }
  .careers-form {
    margin-top: 30px;

    .title.black {
      margin-top: 60px;
    }
  }
  fieldset {
    margin-top: 40px;
    & + fieldset {
      margin-top: 60px;
    }
    &:nth-of-type(3) {
      margin-top: 60px;
    }
    .memo-box {
      min-height: 310px;
      margin-top: 40px;

      textarea {
        height: 205px;
      }
    }
    legend {
      display: flex;
      font-size: 13px;
      line-height: 15px;
      font-weight: bold;
      align-items: center;
      span {
        margin-left: 15px;
        font-size: 11px;
        font-weight: normal;
        color: #b4b4b4;
      }
    }
    .agree-box {
      margin-top: 25px;
      label {
        width: 100%;
      }
    }
    .under-red {
      color: $red;
      text-decoration: underline;
    }
    .form-list {
      margin-top: 30px;
      @include length(3);
      label {
        height: 36px;
        line-height: 36px;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 26px;
        display: inline-flex;
        align-items: center;

        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        letter-spacing: -0.05em;
        transition: all 200ms ease-in-out 0s;
        i {
          display: none;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
        }
        &.disabled {
          cursor: default;
          color: #ddd;
        }
        &.checked {
          background: $red;
          border-color: $red;
          color: #fff;
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .form-select {
      margin-top: 30px;
      option {
        font-family: "Noto Sans KR", sans-serif;
      }
    }
    .form-input-list {
      margin-top: 40px;
      li {
        margin-top: 19px;
        &:first-child {
          margin-top: 0;
        }
        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 11px;
          color: $red;
        }
      }
      input {
        width: 100%;
        height: 35px;
        line-height: 36px;
        outline: none;
        border: solid #ddd;
        border-width: 0 0 1px;
        font-size: 11px;
        &::placeholder {
          font-family: "Noto Sans KR", sans-serif;
          color: #b4b4b4;
        }
        &:focus {
          border-color: $red;
        }
      }
    }
  }
  .careers-btn {
    margin: 50px auto 0;
    text-align: center;
    button {
      width: 120px;
      height: 40px;
      border-radius: 20px;
      background: #ee2c3c;
      border: 0;
      span {
        color: #fff;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 15px;
        font-weight: bold;
      }
      &:disabled {
        background: #ddd;
      }
    }
  }
  .careers-info {
    margin-top: 80px;
    .info-inner {
      position: relative;
      padding-top: 21px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #333;
      letter-spacing: -0.05em;
      word-break: keep-all;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background: $red;
      }

      span {
        color: $red;
        word-break: break-word;
        &.active {
          color: #b4b4b4;
        }
      }
    }
  }
</style>
